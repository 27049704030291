<template>
    <div id="lp1">
    <div class="container">
      <div class="row p-0 m-0" style="min-height:450px;">
        <div class="mx-auto bg-white shadow rounded col-md-12 col-10 p-0 m-0" v-if="!notExists">
           <iframe v-if="iframeUrl != null"  allowtransparency="true" style="width:100%;height:100%;overflow:hidden !important;border:none;border-radius: 8px;" v-bind:src="iframeUrl"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: "Pesquisa",
  directives: {mask},
  mixins: [validationMixin],
  validations: {
    form: {
        accessdoc: {required, minLength: minLength(4)},
    }
  },
  data: () => ({
    themeBackground :'/img/home-natal.jpg',
    imageBackground :'/img/hero-natal.png',
    iframeUrl:null,
    form: {
        accessdoc: null,
        accesscode:null
    },
    error: {
          alert: false,
          status: null,
          message: null
      },
    accessname:'',
    loading : false,
    notExists: false,
    initializing:true
  }),
  methods: {
      ...mapActions('auth', ['ActionGetMailingFirstName','ActionMailingDoLogin']),
      async dologin(){
        this.promoCodeErrors.pop();
        this.error.status = 'ERROR';
        this.$v.$touch();

        if (this.$v.$invalid) {
            // formulário inválido
        } else {
            this.loading = true;
            try {
                await this.ActionMailingDoLogin({
                    DocNumber: this.form.accessdoc,
                    AccessCode: this.form.accesscode,
                    DeviceName: process.env.VUE_APP_API_DEVICE
                })
                this.loading =  false;
                location.href = "/";
            } catch (error) {
                this.promoCodeErrors.push('ocorreu um erro ao autenticar, dados informados inválido');
                this.error.status = 'ERROR';
                this.loading = false;
            }
        }
      }
  },
  async created() {
    this.form.accesscode = this.$route.params.mailing;
    this.iframeUrl =  process.env.VUE_APP_SURVEY_ADM_URL + '?mailing=' + this.$route.params.mailing;
    this.initializing = false;
  },
  computed: {
    promoCodeErrors () {
        const errors = [];

        if(!this.$v.form.accessdoc.$dirty) return errors;
        !this.$v.form.accessdoc.required && errors.push('Campo é obrigatório!');
        !this.$v.form.accessdoc.minLength && errors.push('O CPF/CNPJ não está completo!');

        return errors;
    },
    classMessage: function () {
          return {
              'text-danger': this.error.status == "ERROR",  
              'text-muted': !this.error.status == "ERROR"
          }
      },
  }
};
</script>