<template>
    <div id="lp1" :style="this.largura < 500 ? 'margin-top: -20px; padding-bottom: 20px;' : 'margin-top: -5px; padding-top: 100px; padding-bottom: 100px;'">
    <div class="container">
      <div class="row">
        <div class="col-md-1 pl-4">

        </div>
        <div class="col-md-5 pl-4" :style="this.largura < 500 ? 'margin-top: 25px;' : ''">
          <!-- <img v-if="imageBackground != null" class="img-fluid d-block" v-bind:src="imageBackground" /> -->
          <h1 v-if="imageBackground == null" class="text-left ttsr px-5 my-3 ml-auto" :style="this.largura < 500 ? 'font-size: 22pt; padding-left: 0 !important;' : ''">Acesse e confira as ofertas</h1>
        </div>
        <div class="mx-auto bg-white shadow rounded p-5 col-md-5 col-10 lpp" v-if="notExists">
            <p class="text-muted text-center small mb-1"> Código {{$route.params.mailing}} informado inválido ou inexistente </p>
        </div>

        <div class="mx-auto bg-white shadow rounded p-5 col-md-5 col-10 lpp" v-if="!notExists">
          <div class="mx-auto bg-light p-3 lpp4">
            <div class="border-top-0 py-3 px-3">
              <h4 class="">Olá, {{accessname}}<br></h4>
              <h5 class="text-muted" contenteditable="true">Preencha os campos abaixo</h5>
              <h6>CPF / CNPJ <span class="badge badge-light"> Digite os 4 primeiros números</span></h6>
              <p class="text-muted text-left small mb-1"> Por segurança, digite apenas os 4 primeiros dígitos do seu CPF ou CNPJ: </p>
              <form v-on:submit="dologin" v-on:submit.prevent id="form">  
                <div class="input-group mb-3">
                  <input type="text" aria-label="Recipient's username" aria-describedby="basic-addon" class="form-control"
                  required
                  :disabled="loading"
                  v-model="form.accessdoc"
                  v-mask="['####']">
                  <div class="input-group-append d-flex justify-content-start">
                    <span class="input-group-text text-left" id="basic-addon">xx.xxx-xx ou x.xxx/xxxx-xx</span>
                  </div>
                </div>
                <div class="form-group mb-3 ">
                  <p class="text-left small mb-1 text-muted"> Digite o código de acesso que recebeu:</p> 
                  <input type="text" class="form-control" placeholder="Código de acesso" id="form" label="Digite"
                  v-model="form.accesscode"
                  :disabled="loading">
                  <div class="col-md-8" style="padding:0px;">
                  <div v-if="promoCodeErrors.length"  style="text-align:left;font-size:11px;">
                      <b v-for="error in promoCodeErrors" v-bind:key="error" v-bind:class="classMessage" class="form-text">{{ error }}</b>
                    </div>
                  </div>
                </div>
                <input type="checkbox" name="termos" id="termos" class="float-left" v-model="form.termsAcepted">
                <p class="small ml-4"> Aceito os <a class="text-primary" data-target="#termos" data-toggle="modal" href="#">Termos de negociação.</a>
                </p> <button v-if="!loading" type="submit" class="btn mx-auto btn-primary btn-lg text-white">Prosseguir</button>
                <div class="mt-3">
                  <ProgressLoading v-bind:loading="loading"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from '../../modules/auth/components/ProgressLoading.vue';

export default {
  components: {
      ProgressLoading
  },
  name: "Landing",
  directives: {mask},
  mixins: [validationMixin],
  validations: {
    form: {
        accessdoc: {required, minLength: minLength(4)},
    }
  },
  data: () => ({
    themeBackground : null,
    imageBackground : null,
    form: {
        accessdoc: null,
        accesscode:null,
        termsAcepted: false,

    },
    error: {
          alert: false,
          status: null,
          message: null
      },
    accessname:'',
    loading : false,
    notExists: false,
    largura: null,
  }),
  methods: {
      ...mapActions('auth', ['ActionGetMailingFirstName','ActionMailingDoLogin']),
      async dologin(){
        this.promoCodeErrors.pop();
        this.loading = true;
        if (!this.form.termsAcepted) {
          this.promoCodeErrors.push('Para prosseguir será necessário aceitar os termos de uso');
          this.error.status = 'ERROR';
                this.loading = false;
          console.log(this.promoCodeErrors);
          return;

            }
        this.promoCodeErrors.pop();
        this.error.status = 'ERROR';
        this.$v.$touch();

        if (this.$v.$invalid) {
            // formulário inválido
        } else {
            this.loading = true;
            try {
                await this.ActionMailingDoLogin({
                    DocNumber: this.form.accessdoc,
                    AccessCode: this.form.accesscode,
                    DeviceName: process.env.VUE_APP_API_DEVICE
                })
                this.loading =  false;
                location.href = "/";
            } catch (error) {
                this.promoCodeErrors.push('ocorreu um erro ao autenticar, dados informados inválido');
                this.error.status = 'ERROR';
                this.loading = false;
            }
        }
      }
  },
  async created() {
    this.loading = true;
    this.largura = window.screen.width;
    this.form.accesscode = this.$route.params.mailing;
    var result = await this.ActionGetMailingFirstName(this.form.accesscode);
    if(result.data.error){
      //this.$router.push({ path: '/',foce:true });
      this.notExists = true;
    }else{
      this.accessname = result.data.results.FirstName;
    }
    this.loading = false;
   
  },
  computed: {
    promoCodeErrors () {
        const errors = [];

        if(!this.$v.form.accessdoc.$dirty) return errors;
        !this.$v.form.accessdoc.required && errors.push('Campo é obrigatório!');
        !this.$v.form.accessdoc.minLength && errors.push('O CPF/CNPJ não está completo!');

        return errors;
    },
    classMessage: function () {
          return {
              'text-danger': this.error.status == "ERROR",  
              'text-muted': !this.error.status == "ERROR"
          }
      },
  }
};
</script>