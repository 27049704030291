<template>
      <div class="row">
        <div class="col-12">
          <img src="/img/icon-error.png">
        </div>
      </div>
</template>

<script>

export default {
  name: "Erro",
  data: () => ({
    largura: null,
    links: [
      
    ]
  }),
  methods: {

  },
  created() {
    this.largura = window.screen.width;

  },
  computed: {
  }
};
</script>
<style scoped>

.container {
  background-color: #F5F6FA !important;
}

img {
  max-width: 100%;
}

/* @media (max-width: 500px) {
  img {
    max-width: 100%;
  }
} */

</style>